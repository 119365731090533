import React from 'react'
import './Graph.css';

function Graph() {
  return (
    <div id="graph-container" >
    <iframe
      id="graph-container"
      src="https://neo4j.cube-innova.toyota.com/api/graph"
      width="100%"
      height="100%"
    />
    </div>
  )
}

export default Graph