import React, { useState, useEffect } from 'react';
import toyota_logo2 from '../assets/toyota_logo2.png';
import cubeLogo from '../assets/cubeLogo.png'
import './Main.css';
import Vehiclehub from '../Vehiclehub/Vehiclehub';
import Errorpage from '../common/error/error';
import Loader from '../common/loader/Loader';
//import  Logger  from '../Logs/Logs';
import { v4 as uuidv4 } from 'uuid';

function Main() {
  const [isLoader, setIsLoader] = useState(true)
  const [isError, setIsError] = useState(false)
  const [tab, setTab] = React.useState('Vehicle Hub');
  const [data, setData] = useState({})
  const [sessionId, setSessionId] = useState('');


  useEffect(() => {
    const sessionId = uuidv4();
    setSessionId(sessionId);  
  }, []);

 //console.log(sessionId)

  useEffect(() => {
    
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch('https://neo4j.cube-innova.toyota.com/api/data');
      const jsonData = await response.json();
      setData(jsonData)
      setIsLoader(false)
      console.log("Connected to the server")
      //Logger.info('connected to server');

    }
    catch (err) {
      setIsLoader(false)
      setIsError(true)
      console.log('Unable to connect to the server')
      //Logger.info('Unable to server');
    }
  }


  return (
    <div>
      {isLoader && <div id="mainloader"><Loader /></div>}
      {isError && !isLoader && <Errorpage />}
      {!isError && !isLoader && <div className="wrapper" id="wrapper">
        <div className="header">
          <img className="logo" src={toyota_logo2} />
          <div className="bar"></div>
          <p className="titel">Gen AI Enabled Q&A Tool Powered by Graph Database</p>
        </div>
        <div className='subheader'>
          <img className="logo2" src={cubeLogo} />
          <p className="titel2">Explore and seek insights through simple Q&A with data.</p>
        </div>
        <div className="tab-container">
          <ul className='tabs'>
            <li className={tab === 'Vehicle Hub' ? 'active' : ''} onClick={() => setTab('Vehicle Hub')}>
              Vehicle Hub
            </li>
            {data.flag && (
              <li className={tab === 'Guide Hub' ? 'active' : ''} onClick={() => setTab('Guide Hub')}>
                Guide Hub
              </li>
            )}
          </ul>
        </div>
        {tab === 'Vehicle Hub' && (
          <div className="tab-content">
            <Vehiclehub sessionId={sessionId}/>
          </div>
        )}
        {tab === 'Guide Hub' && (
          <div className="tab-content">
            {data.flag}
          </div>
        )}
      </div>}
    </div>
  )
}

export default Main