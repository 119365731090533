import React, { useState, useEffect, createElement } from 'react'
import './Vehiclehub.css';
import expand from '../assets/expand.png'
import Graph from '../common/Graph/Graph';
import Loader from '../common/loader/Loader';



function Vehiclehub({sessionId}) {
  
  //console.log(sessionId)

  const [query, setQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [encodedData, setEncodedData] = useState('');
  const [answer, setAnswer] = useState('');
  const [tableToText, setTableToText]=useState('');
  const[graphFlag,setGraphFlag]=useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
 
  // Function to create a table from JSON data
  function createTableFromJson(jsonData) {
    const jsonDataArray = JSON.parse(jsonData);
    const table = document.createElement('table');
    table.className = "table";
    // Create header row
    const headerRow = document.createElement('tr');
    for (const key in jsonDataArray[0]) {
      const headerCell = document.createElement('th');
      headerCell.textContent = key;
      headerRow.appendChild(headerCell);
    }
    table.appendChild(headerRow);
    // Create rows and cells for each JSON object
    jsonDataArray.forEach(obj => {
      const row = document.createElement('tr');
      for (const key in obj) {
        const cell = document.createElement('td');
        cell.textContent = obj[key];
        row.appendChild(cell);
      }
      table.appendChild(row);
    });
    return table;
  }
  
  function createAnswer(input) {
    const chat = document.getElementById('chat');
    const ans = document.createElement('div');
    ans.innerHTML = input;
    ans.className = "answer";
    chat.appendChild(ans);
  }

  function createQuestion(inputQuestion){
    const chat = document.getElementById('chat');
    const question = document.createElement('div');
    question.innerHTML = 'Q.' + inputQuestion;
    question.className = "question";
    chat.appendChild(question);
    console.log(`Input Query:\n${query}`)
  }
  
  const expandGraph = () => {
    const container = document.getElementById('neograph_container');
    if (isFullScreen) {
      // revert to original styles
      container.className= 'neograph_collapse';

    } else {
      // set styles to full screen
      container.className = 'neograph_expand';

    }
    setIsFullScreen(!isFullScreen);
  };

  const handelSubmit = async (e) => {
    try {
      console.log("Submit Button Clicked")
      e.preventDefault();
      const tableContainer = document.getElementById('tableContainer')
      const plotContainer = document.getElementById('plotContainer')
      setErrorMessage("");
      setTableToText("");
      setAnswer("");
      setGraphFlag("");
      tableContainer.innerHTML = '';
      setEncodedData("")
      if (query === "") { setErrorMessage("Input Can't be blank")
                          console.log("Input Can't be blank")
       }
      else if (query.split(' ').length < 3) { setErrorMessage("query should have atleast three words"); 
                                               console.log("query should have atleast three words")
      }

      else {
        setIsLoader(true)
        createQuestion(query)
        console.log("fetching data from backend")
        const response = await fetch('https://neo4j.cube-innova.toyota.com/api/submit', {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query,sessionId }),
        });
        const result = await response.json();
        result && console.log(`Received Response from backend`)
        result.answer && console.log(`Received cypherquery : \n${result.answer}`)
        result.table && console.log(`Received tabledata:\n${result.table}`)
        result.tabletext && createAnswer(result.tabletext);
        result.error && createAnswer(result.error);
        result.error && setErrorMessage(result.error);
        result.error && console.log(`Received error message:\n${result.error}`)
        result.error && setIsLoader(false)
        result.tabletext && setTableToText(result.tabletext);
        result.tabletext && console.log(`Received summary is:\n${result.tabletext}`)
        result.table && tableContainer.appendChild(createTableFromJson(result.table));
        result.encoded && setEncodedData(result.encoded)
        result.encoded && console.log("Dispaly plot")
        result.answer && setAnswer(result.answer)
        result.answer && setIsLoader(false)
        result.graphflag && setGraphFlag(result.graphflag)
        result.graphflag && console.log("Display graph")
        
      }
    } catch (error) {
      setIsLoader(false);
      setErrorMessage('I apologize, but I cannot process your submit request at this moment'); // Optional: show an alert to the user
      console.log("Unable to fetch the response")
    }
  };





  return (
    <div>
      <div className="vehicle-wrapper" id="wrapper">
        <div className="q-section">
          <div className="Question">
            Sample Questions
            <select className="dropdown" onChange={(e) => setQuery(e.target.value)}>
              <option>What are the top five exterior color by vehicle count</option>
              <option>Which dealer is serving to how many region?</option>
              <option>Give me the dealer with highest number of orders.</option>
            </select>
            Enter your Query here
            <form onSubmit={handelSubmit}>
              <textarea className="query-section" value={query} onChange={(e) => setQuery(e.target.value) } ></textarea>
              <button type="submit" className="submit-button">Submit</button>
            </form>
          </div>
          <div className="chatcomponent">Chat history
            <div id="chat" className="chatbox"></div>
          </div>
        </div>
        {isLoader && <div id="vehicleloader"><Loader/></div>}
        {errorMessage && <div id="errorbox">{errorMessage}</div>}
        <div className="dataContainer">
          <div id="tableContainer"></div>
          <div className='plotContainer' id="plotContainer">
            {encodedData && (<img src={`data:image/png;base64,${encodedData}`} />)}
          </div>
        </div>
        {tableToText && <div id="tableToText">{tableToText}</div>}
        <div id='answerContainer'>
          {answer && (<textarea className="answerbox">{answer}</textarea>)}
          {graphFlag && (<div id="neograph_container" className='neograph_collapse'><img id="expand"src={expand} alt="icon" onClick={() => expandGraph()}/><Graph/></div>)}
        </div>
        
      </div>
    </div>
  )
}

export default Vehiclehub