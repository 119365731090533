import React, { useState, useEffect } from 'react';
import './error.css';


function Errorpage() {
  
  return (
    <div id="error">
       <h3>Connection To server Failed</h3>
    </div>
  )
}

export default Errorpage