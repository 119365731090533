  import React from 'react'
  import Box from '@mui/material/Box';
  import CircularProgress from '@mui/material/CircularProgress';
  import './Loader.css';
  function Loader() {
    return (
      <div> <div id="loader">
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            </Box>
            </div>
    </div>
    )
  }
  
  export default Loader